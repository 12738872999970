<template>
  <div class="pay">
    <div class="title"></div>
    <vue-qr :correctLevel="3" :autoColor="false" :download="downloadFilename" :text="url" :logoSrc="imageUrl" :size="200" :margin="0" :logoMargin="3"></vue-qr>
    <p class="paymoney">支付金额：{{ obj.servicePrice }}元</p>
    <div class="col align-center pay-info">
      <template v-for="item in info">
        <div class="info row justify-between" :key="item.field" v-if="!(item.fieldText === '首付款' && obj && obj['installment'] === '全款')">
          <div class="field">{{ item.fieldText }}</div>
          <div class="fieldVal">{{ obj[item.field] ? obj[item.field] : '' }}</div>
        </div>
      </template>
    </div>
    <div style="margin: 16px;" class="row justify-around btns">
      <van-button round block type="default" @click="handlePre">上一步</van-button>
      <van-button round block type="info" class="margin-left-20" @click="close">关闭</van-button>
    </div>
  </div>
</template>
<script>
  import vueQr from 'vue-qr'
  import storage from '../../utils/storage'
  const info = [
    { fieldText: '服务名称', field: 'product' },
    { fieldText: '经销商', field: 'franchiserCode' },
    { fieldText: '客户姓名', field: 'customerName' },
    { fieldText: '手机号码', field: 'contactPhone' },
    { fieldText: '身份证号码', field: 'certificateNo' },
    { fieldText: '服务价格', field: 'servicePrice' },
    { fieldText: '付款方式', field: 'installment' },
    { fieldText: '首付款', field: 'firstPayment' }
  ]
  export default {
    components: {
      vueQr
    },
    data() {
      return {
        info,
        obj: {},
        // url: `${this.$baseH5Url}/extended/customer_comfirm?insuranceNo=${storage.getItem('orderInfo').insuranceNo}`,
        imageUrl: require(`@/assets/logo.png`),
        downloadFilename: '',
        insuranceNo: ''
      }
    },
    created() {
      const obj = storage.getItem('orderInfo')
      this.insuranceNo = this.$route.query.insuranceNo ? this.$route.query.insuranceNo : obj.insuranceNo
      obj.installment = obj.installment ? '分期' : '全款'
      if (obj && obj.productIds) this.obj = obj
    },
    computed: {
      url() {
        return `${this.$baseH5Url}/extended/customer_comfirm?insuranceNo=${this.insuranceNo}`
      }
    },
    methods: {
      handlePre() {
        window.history.go(-1)
      },
      close() {
        this.$router.push({path: '/Catalogue/serviceList', query: {userid: this.obj.userId, username: this.obj.userName} })
      }
    }
  }
</script>
<style scoped>
  .pay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .paymoney {
    font-size: 22px;
    margin-top: 20px;
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 80px;
  }

  .pay-info {
    width: 100%;
    padding: 0 40px;
    margin-top: 30px;
  }

  .info {
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
    padding: 6px;
    border-bottom: 1px solid #ddd;
  }

  .info .field {
    color: #333;
    width: 150px;
  }
  .info .fieldVal {
    color: #666;
  }

  .btns {
    width: 100%;
    padding: 20px 40px 0;
  }
</style>